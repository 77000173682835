@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.author {
  composes: p from global;
  margin: 0px 24px 32px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    margin: 12px 0 36px 0;
    color: var(--colorGrey700);
  }
}

.badge {
  margin-left: 8px;
  display: flex;
  border-radius: 25px;
  padding: 2px 8px;
  align-items: center;
}

.bronze {
  background: rgba(188, 98, 32, 1);
}

.silver {
  background: rgba(177, 177, 177, 1);
}

.gold {
  background: rgba(247, 158, 27, 1);
}

.platinum {
  background: rgba(0, 61, 121, 1);
}

.iconBadge {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.badgeType {
  color: white;
  text-transform: capitalize;
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.buttonContainer {
  padding: 24px;
  
  @media (--viewportMedium) {
    padding: 0;
  }
}

.favoriteButton {
  margin: 20px 0 20px 0;
  border-style: solid;
  border-color: var(--marketplaceColor);
  border-width: 1px;
  color: var(--colorWhite);
}

.favoriteButton:hover {
  color: var(--colorWhite);
  border-style: solid;
  border-color: var(--colorWhite);
  border-width: 1px;
}

.favoriteButton:hover path {
  fill: gold;
}

.favicon {
  vertical-align: bottom;
  margin-right: 5px;
}

.unfavicon {

}

.unfavoriteButton {
  margin: 20px 0 20px 0;
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  border-style: solid;
  border-color: var(--marketplaceColor);
  border-width: 1px;
}

.unfavoriteButton path{
  fill: gold;
}

.unfavoriteButton:hover {
  background-color: var(--marketplaceColorDark);
  color: var(--colorWhite);
  border-style: solid;
  border-color: var(--colorWhite);
  border-width: 1px;
}

.unfavoriteButton:hover path {
  fill: var(--colorWhite);
}

.unfavoriteButton:focus {
  background-color: var(--marketplaceColorDark);
}

.favoriteButton:focus {
  background-color: var(--marketplaceColorDark);
}

.contact {
  background-color: var(--colorGrey500);
  color: var(--colorWhite);
}

.contact:hover {
  background-color: var(--colorGrey600);
}

.commentIcon {
  vertical-align: bottom;
  margin-right: 5px;
}

.returnButton {
  margin-top: 20px;
  background-color: var(--colorSuccess);
}

.returnButton:hover {
  background-color: green;
}